import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled, { css } from 'styled-components'
import Button from '../components/Button'
import Heading from '../components/Heading'
import Layout from '../components/Layout'
import List from '../components/List'
import ListItem from '../components/ListItem'
import PagePadding from '../components/PagePadding'
import Seo from '../components/Seo'
import ServiceType from '../types/ServiceType'

type ServicePreview = Pick<
  ServiceType,
  'name' | 'path' | 'possibilities' | 'heroImage' | 'thumbnailImage'
>

const ServicesPage = () => {
  const data = useStaticQuery<{
    allSanityService: {
      edges: {
        node: ServicePreview
      }[]
    }
  }>(graphql`
    query AllServicesQuery {
      allSanityService(sort: { order: ASC, fields: [listPosition, name] }) {
        edges {
          node {
            name
            path
            possibilities
            heroImage {
              ...ServiceThumbnailImage
            }
            thumbnailImage {
              ...ServiceThumbnailImage
            }
          }
        }
      }
    }
    fragment ServiceThumbnailImage on SanityWebImage {
      alt
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          height: 300
          placeholder: NONE
          width: 330
        )
      }
    }
  `)
  const services = data.allSanityService.edges.map(edge => edge.node)
  return (
    <Layout pageTitle="Services">
      <Seo
        title="Services"
        description="Hand-Lettered signage, bespoke stationery services and modern calligraphy workshops. Ontario calligrapher serving weddings, brand activations & events in the GTA & Eastern Ontario."
      />
      <Content>
        <ul>
          {services.map((service, i) => {
            const {
              name,
              path,
              possibilities,
              thumbnailImage,
              heroImage,
            } = service
            const imageConfig = thumbnailImage?.asset
              ? thumbnailImage
              : heroImage
            return (
              <ServiceSummary key={name} index={i}>
                <ServiceDescription index={i}>
                  <ServiceName>{name}</ServiceName>
                  <List>
                    {possibilities.map(possibility => {
                      return (
                        <ListItem key={possibility}>{possibility}</ListItem>
                      )
                    })}
                  </List>
                  <ButtonLink as={Link} to={path}>
                    Learn more
                  </ButtonLink>
                </ServiceDescription>
                <ServiceImageLink to={path}>
                  <ServiceImage
                    image={imageConfig.asset.gatsbyImageData}
                    alt={imageConfig.alt}
                  />
                </ServiceImageLink>
              </ServiceSummary>
            )
          })}
        </ul>
      </Content>
    </Layout>
  )
}

export default ServicesPage

const Content = styled(PagePadding)`
  padding-top: 4rem;
  padding-bottom: 4rem;
`

const ButtonLink = styled(Button)`
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`

const ServiceSummary = styled.li<{ index: number }>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  transition: border-color 0.3s, transform 0.3s;

  @media (min-width: 700px) {
    flex-direction: row;
    align-items: flex-start;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: 900px) {
    position: relative;
    ${({ index }) =>
      index % 2 === 0
        ? css`
            left: 3rem;
          `
        : css`
            right: 3rem;
          `}
  }

  &:hover {
    transform: scale(1.0125);
  }

  .gatsby-image-wrapper {
    transition: transform 0.4s;
  }

  &:hover .gatsby-image-wrapper {
    transform: scale(1.05);
  }
`

const ServiceDescription = styled.div<{ index: number }>`
  flex: 1;
  margin-bottom: 2rem;

  @media (min-width: 700px) {
    margin-right: 2rem;
    ${({ index }) =>
      index % 2 !== 0 &&
      css`
        order: 1;
        margin-left: 2rem;
      `}
  }
`

const ServiceImage = styled(GatsbyImage)<{ image: any }>`
  width: 300px;
  max-width: 100%;
`

const ServiceImageLink = styled(Link)`
  max-width: 100%;
`

const ServiceName = styled(Heading)`
  margin: 0;
  position: relative;
  left: 1rem;
  /* text-align: center; */
`
